import { IoIosCheckmarkCircle } from "react-icons/io";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameButton from "../buttons/FrameButton";
import { IoClose } from "react-icons/io5";
import { restaurantSearchRedirect } from "@/utils/restaurantSearchRedirect";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { useNavigate } from "react-router-dom";
import * as LoadingIndicators from "react-loading-indicators";

interface NftPromoModalLoadingProps {
    loading: true;
    isOpen: boolean;
    onClose: () => void;
}

interface NftPromoModalReadyProps {
    loading: false;
    isOpen: boolean;
    image: string;
    dateMinted: Date;
    description: string;
    onClose: () => void;
}

type NftPromoModalProps = NftPromoModalLoadingProps | NftPromoModalReadyProps;

// box-shadow: 0px 0px 8px -4px rgba(127, 86, 217, 1);

// box-shadow: 0px 0px 24px -4px rgba(128, 79, 211, 1);


export const NftPromoModal = (props: NftPromoModalProps) => {
    if (props.loading) {
        return (
            <FrameOneModal contentClassName="nft-promo-modal" isOpen={props.isOpen} toggle={props.onClose}>
                <div className="nft-promo-modal_loading_container">
                    <h4 className="nft-promo-modal_loading_cta">
                        Hang Tight!
                    </h4>
                    <div className="nft-promo-modal_loading_description">Your reward is coming your way! Please give a couple of seconds. Thank you.</div>
                    <LoadingIndicators.BlinkBlur color="rgba(170, 150, 239, 1)" size="medium" text="" textColor="" />
                </div>
            </FrameOneModal>
        );
    }

    const {dateMinted, image, description, isOpen, onClose} = props as NftPromoModalReadyProps;

    const dateMintedString = dateMinted.toLocaleDateString("en-US", { year: "numeric",
        month: "short",
        day: "numeric" });

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);

    const navigate = useNavigate();

    const onClickUsePromo = async () => {
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    };


    return (
        <FrameOneModal contentClassName="nft-promo-modal" isOpen={isOpen} toggle={onClose}>
            <div className="nft-promo-modal_container">
                <div className="nft-promo-modal_x-button" onClick={onClose}><IoClose size={24}/></div>
                <img src={image} alt={"Promo Minted"} style={{marginBottom: "1.25rem",
                    maxWidth: "70%"}}/>
                <div>
                    <h4 className="nft-promo-modal_cta">Promotion Claimed <span style={{marginLeft: "0.48rem"}}><IoIosCheckmarkCircle size={20}/></span></h4>
                    <p style={{color: "rgba(135, 135, 141, 1)",
                        fontSize: "0.72rem",
                        marginBottom: "0.6rem",
                        marginBlock: "0px 0px"}}>Claimed on {dateMintedString}</p>
                </div>
                <p className="nft-promo-modal_description">{description}</p>
                <FrameButton color="purple" className="nft-promo-modal_use-promo-button" onClick={onClickUsePromo}><span className="nft-promo-modal_use-promo-button_text">Use Promo Now</span></FrameButton>
                <FrameButton className="nft-promo-modal_close-secondary-button" color="purple-text-no-border" onClick={onClose}><span className="nft-promo-modal_close-secondary-button_text">Close</span></FrameButton>
            </div>
        </FrameOneModal>
    );
};